.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.truncate-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-ellipsis {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.reports-card-section {
  height: 67px;
}

@media(max-width: 576px) {
  .reports-card-section {
    height: auto;
  }
}

@media(max-width:768px) {}

.total-sales-section {
  display: flex;
  align-items: center;

}

@media(max-width: 576px) {
  .total-sales-section {
    flex-direction: column;
  }
}

.accounts-flex {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1vw;
  flex-wrap: wrap;
}

@media(max-width: 576px) {
  .accounts-flex {
    flex-direction: column;
  }
}

.accounts-card {
  width: 24%;
}

@media(max-width: 576px) {
  .accounts-card {
    width: 100%;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 110px!important;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block!important;
  margin-right: 4px!important;
  color: #ff6b72!important;
  font-size: 14px!important;
  font-family: SimSun,sans-serif!important;
  line-height: 1!important;
  content: "*";
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none!important;
}

.ant-menu-item-group-title {
  padding: 12px 24px;
  color: rgba(26,51,83,.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
  opacity: 0!important;
}

.invoice-table .ant-table-tbody > tr > td ,
.invoice-table .ant-table-tbody > tr > th 
 {
  border: none!important;
  padding-top: 0!important;
}

.invoice-table .ant-table-thead > tr > th {
  border-bottom: none!important;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  /* top: -1px; */
  color: rgb(62, 121, 247);
  line-height: 1;
}

/* .invoice-table{
border: none!important;
} */

.invoice-modal .ant-modal-body {
  padding: 0!important;
  font-size: 14px;
  line-height: 1.5;
  overflow-wrap: break-word;
 
}
.invoice-modal{
  top:50%!important;
  transform:translateY(-50%)!important;
}

.invoice-download{

  display:flex;
  align-items:center;
  
}

/* .invoice-table .ant-modal-header {
  border-bottom: none!important;
} */

.product-modal{
  position: relative!important;
  top: 50%!important;
  transform: translateY(-50%)!important;
  
}

.invoice-table td{
  display: table-cell;
    vertical-align: bottom!important;

}

.register-label{
  font-weight: 500;
  
}

.phone-verify-modal{
  position: relative!important;
  top: 50%!important;
  transform: translateY(-50%)!important;
  
}

.add-invoice-modal{
  position: relative!important;
  top: 50%!important;
  transform: translateY(-50%)!important;
}

.invoice-hsn{
  appearance: textfield;
}